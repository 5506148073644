<template>
  <Widget :planets="this.planets" :satellites="this.satellites" :baseUrl="this.baseUrl"></Widget>
</template>

<script>
import Widget from './components/Widget.vue'
import axios from 'axios'

// TODO remove these for production
// import planets from "../public/data/planets.json"
// import satellites from "../public/data/satellites.json"


export default {
  name: 'App',
  components: {
    Widget
  },
  data() {
    return {
      planets: [],
      satellites: [],
      baseUrl: 'https://sat-track-api.serenitec.com'
    }

  },
  created: function () {
    this.fetchData()
  },
  methods: {
    fetchData() {

      let self = this

      if (window.location.href === 'http://localhost:8080/') {
        this.baseUrl = 'http://localhost:8000'
      }

      // actually fetch data file from the backend to have a consistent record.
      const urlSatellites = this.baseUrl + '/satellites'
      axios.get(urlSatellites)
          .then(response => {
            self.satellites = response.data['satellites']
          })
          .catch(error => {
            // satellites['satellites']
            console.error(error)   // todo - is there anything?
          })


      const urlPlanets = this.baseUrl + '/planets'
      axios.get(urlPlanets)
          .then(response => {
            self.planets = response.data['planets']
          })
          .catch(error => {
            // planets['planets']
            console.error(error)   // todo - is there anything?
          })

    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

canvas {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
}
</style>
