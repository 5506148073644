<template>
  <selection-controls :planets="planets"
                      :satellites="satellites"
                      @timeupdate="handleTimeChange">
  </selection-controls>
  <div v-if="Object.keys(trajectories['times']).length > 0" class="animation-controls-section">
    <div class="animation-controls">
      <img src="assets/play-pause.svg" class="play-pause-centre" @click="toggleAnimate"/>

      <vue-slider
          v-if="animateFrames > 0"
          v-model="this.animateIndex"
          :min="0"
          :max="animateFrames"
          :height="10"
          :process-dragable="true"
          :tooltip="'none'"
          @change="setAnimateIndex"
          format="tooltip"
          :process-style='{"backgroundColor": "rgb(1,130,196)"}'
          class="time-slider"
      ></vue-slider>

      <img src="assets/focus-so-svgrepo-com.svg" class="play-pause-centre" @click="initialiseView"/>
    </div>
    <h3>{{ currentTime }}</h3>
  </div>
  <!--  <animation-controls v-if="Object.keys(trajectories['times']).length > 0"-->
  <!--                      :animating="animating"-->
  <!--                      :times="Object.values(trajectories['times'])"-->
  <!--                      :timeIndex="animateIndex"-->
  <!--                      @toggleAnimate="handleToggleAnimate">-->
  <!--  </animation-controls>-->
</template>

<script>
import SelectionControls from "./SelectionControls";


// TODO lazy load all objects

import * as THREE from "three";
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js';
import {TrackballControls} from 'three/examples/jsm/controls/TrackballControls.js';
import axios from "axios";
import VueSlider from 'vue-slider-component'

// import Stats from 'three/examples/jsm/libs/stats.module.js';

let perspectiveCamera, orthographicCamera, controls, renderer, scene, loader, tickerTime;
// , stats;
const params = {
  orthographicCamera: false
};
const frustumSize = 400;
const planetBodyScale = 0.0002
const satelliteBodyScale = 0.02

let bodies = {}

export default {
  name: 'Widget',
  components: {SelectionControls, VueSlider},
  data() {
    let aspect = window.innerWidth / window.innerHeight;
    tickerTime = new Date();
    perspectiveCamera = new THREE.PerspectiveCamera(60, aspect, 0.01, 1000);
    orthographicCamera = new THREE.OrthographicCamera(frustumSize * aspect / -2, frustumSize * aspect / 2, frustumSize / 2, frustumSize / -2, 0.01, 1000);
    // TODO test if this in needed
    perspectiveCamera.position.z = 3;
    orthographicCamera.position.z = 3;
    return {
      aspect: aspect,
      startTime: new Date(),
      intervalSeconds: 0,
      forecastTimeSeconds: 0,
      perspectiveCamera: perspectiveCamera,
      orthographicCamera: orthographicCamera,
      trajectories: {"times": {}},
      animateIndex: 0,
      animateFrames: 0,
      animating: true
    }
  },
  mounted() {
    window.addEventListener('resize', this.onWindowResize);
  },
  unmounted() {
    window.removeEventListener('resize', this.onWindowResize);
  },
  props: {
    planets: {
      type: Array, default: () => ([])
    },
    satellites: {
      type: Array, default: () => ([])
    },
    baseUrl: String,
    msg: String,
  },
  watch: {
    planets: {
      handler: function (newVal, oldVal) {
        if (oldVal.length === 0) {
          console.log("planets changed", this.planets)
          this.setupBodies('planets')
        }
        this.getTrajectories()
        this.updateBodies(this.planets)
      },
      deep: true
    },
    satellites: {
      handler: function (newVal, oldVal) {
        if (oldVal.length === 0) {
          this.setupBodies('satellites')
        }
        this.getTrajectories()
        this.updateBodies(this.satellites)
      },
      deep: true
    },
  },
  // TODO debug duplicated compute values
  computed: {
    currentTime() {
      return new Date(this.trajectories['times'][this.animateIndex]).toUTCString()
    },
    selectedSats() {
      return this.satellites.filter(function (sat) {
        return sat.include
      })
    },
    qstringSats() {
      return this.satellites.filter(function (sat) {
        return sat.include
      }).map(x => `&satellite=${x.name}`).join('')
    },
    selectedPlans() {
      return this.planets.filter(function (plan) {
        return plan.include
      })
    },
    qstringPlans() {
      return this.planets.filter(function (plan) {
        return plan.include
      }).map(x => `&planet=${x.name}`).join('')
    },
    qstringTimes() {
      return `startTime=${this.startTime.toISOString()}&forecastTimeSeconds=${this.forecastTimeSeconds}&intervalSeconds=${this.intervalSeconds}`
    },
    selectedBodies() {
      return this.selectedPlans.concat(this.selectedSats)
    }
  },
  created() {
    this.initialise()
    this.animate()
  },
  methods: {
    initialise() {
      // Setup cameras
      // Cameras setup above in the data step?

      // WORLD
      scene = new THREE.Scene();
      loader = new GLTFLoader();


      // Add planets
      this.setupBodies('planets')
      // Add Satellites
      this.setupBodies('satellites')
      // Add background and lights
      this.setupBackgroundAndLights()

      // renderer
      renderer = new THREE.WebGLRenderer({antialias: true});
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth, window.innerHeight);
      document.body.appendChild(renderer.domElement);


      // var visDiv = document.createElement("div");
      // visDiv.id = "visualisation"
      // document.body.prepend(visDiv)
      // console.log(document)
      // var visEl = document.getElementById("visualisation")
      // visEl.appendChild(renderer.domElement);

      // stats = new Stats();
      // document.body.appendChild(stats.dom);

      //

      // const gui = new GUI();
      // gui.add(params, 'orthographicCamera').name('use orthographic').onChange(function (value) {
      //
      //     controls.dispose();
      //
      //     createControls(value ? orthographicCamera : perspectiveCamera);
      //
      // });


      this.createControls(perspectiveCamera);

    },
    getTrajectories() {
      if (`${this.qstringSats}${this.qstringPlans}` === '') {
        return
      }

      var urlTracking = this.baseUrl + `/tracking?${this.qstringTimes}${this.qstringPlans}${this.qstringSats}`
      axios.get(urlTracking)
          .then(response => {
            this.trajectories = response.data['trajectories']
            this.animateFrames = Object.keys(this.trajectories['times']).length
            this.animateIndex = 0
          })
          .catch(error => {
            console.error(error)   // todo - is there anything?
          })

    },
    handleTimeChange(timeUpdates) {
      console.log(timeUpdates)
      this.forecastTimeSeconds = timeUpdates['forecastTimeMins'] * 60
      this.startTime = timeUpdates['startTime']
      this.intervalSeconds = timeUpdates['intervalMins'] * 60
      this.getTrajectories()
    },
    toggleAnimate() {
      this.animating = !this.animating
      console.log("animate changed")
    },
    updateBodies(whichBodies) {
      let addBodyScene = this.addBodyScene
      let removeBodyScene = this.removeBodyScene
      whichBodies.forEach(body => {
        let inScene = scene.getObjectByName(body.name)
        let shouldInclude = body['include']
        if (!inScene && shouldInclude) {
          addBodyScene(body)
        }
        if (inScene && !shouldInclude) {
          removeBodyScene(body)
        }
      })
    },
    createControls(camera) {

      controls = new TrackballControls(camera, renderer.domElement);

      controls.rotateSpeed = 1.0;
      controls.zoomSpeed = 1.2;
      controls.panSpeed = 0.8;
      controls.update(3.5)

      controls.keys = ['KeyA', 'KeyS', 'KeyD'];

    },
    addBodyScene(body) {
      console.log("adding " + body.name)
      if (bodies[body.name]) {
        scene.add(bodies[body.name])
      }
      // this.animate()
    },
    removeBodyScene(body) {
      console.log("removing " + body['name'])
      var selectedBody = scene.getObjectByName(body.name);
      scene.remove(selectedBody)
      // this.animate()
    },
    setupBodies(body_type) {
      const body_types = ['satellites', 'planets']
      if (body_types.indexOf(body_type) < -1) {
        console.error("Body type is not supported:" + body_type)
      }

      var bodyScale = planetBodyScale
      if (body_type === 'satellites') {
        bodyScale = satelliteBodyScale
      }

      let vueThis = this
      for (const body of this[body_type]) {

        console.log("loading " + body.filename)
        loader.load("./data/solar_system/" + body_type + "/" + body.filename, function (glb) {
          glb.scene.name = body.name
          bodies[body.name] = glb.scene
          bodies[body.name].scale['x'] = bodyScale
          bodies[body.name].scale['y'] = bodyScale
          bodies[body.name].scale['z'] = bodyScale

          // console.log(bodies[body.name].scale)
          if (body.include) {
            vueThis.addBodyScene(body)
          }
        }, undefined, function (error) {
          console.error(error);
        });

      }

    },
    setupBackgroundAndLights() {

      // TODO moving background
      // https://stackoverflow.com/questions/45443196/how-to-set-up-image-background-in-three-js
      const textureLoader = new THREE.TextureLoader();
      textureLoader.load('./data/solar_system/star_background.jpg', function (texture) {
        scene.background = texture;
      });
      // scene.background = new THREE.Color(0xcccccc);

      // lights
      // TODO play with to make render better
      const dirLight1 = new THREE.DirectionalLight(0xffffff, 2);
      dirLight1.position.set(1, 1, 1);

      const ambientLight = new THREE.AmbientLight(0xffffff, 2);
      scene.add(dirLight1);
      scene.add(ambientLight);
    },
    animate() {
      const camera = (params.orthographicCamera) ? orthographicCamera : perspectiveCamera;
      renderer.render(scene, camera);
      controls.update();

      this.render()
    },
    initialiseView() {
      // var noValues = (this.selectedSats.length + this.selectedPlans.length) * this.animateFrames

      var x = [], y = [], z = []
      for (const key in this.trajectories) {
        if (key === 'times') {
          continue
        }
        x = x.concat(this.trajectories[key].map(val => val['x']))
        y = y.concat(this.trajectories[key].map(val => val['y']))
        z = z.concat(this.trajectories[key].map(val => val['z']))
      }

      // TODO
      // get min max xyz??
      // set the camera position 80% to planar??
      // Scale the planets on ZOOM.
      // Draw lines - API update needed.
      // resetView button
    },
    render() {

      requestAnimationFrame(this.animate);

      if ((new Date() - tickerTime) >= 1000) {
        if (this.animateFrames && this.animating) {
          for (const body of this.selectedBodies) {
            if (bodies[body.name]) {
              bodies[body.name].position['x'] = this.trajectories[body.name][this.animateIndex]['x']
              bodies[body.name].position['y'] = this.trajectories[body.name][this.animateIndex]['y']
              bodies[body.name].position['z'] = this.trajectories[body.name][this.animateIndex]['z']
            }
          }

          // TODO add satellites


          this.animateIndex = (this.animateIndex + 1) % this.animateFrames
        }
        tickerTime = new Date()
      }
    },
    onWindowResize() {

      this.aspect = window.innerWidth / window.innerHeight;

      perspectiveCamera.aspect = this.aspect;
      perspectiveCamera.updateProjectionMatrix();

      orthographicCamera.left = -frustumSize * this.aspect / 2;
      orthographicCamera.right = frustumSize * this.aspect / 2;
      orthographicCamera.top = frustumSize / 2;
      orthographicCamera.bottom = -frustumSize / 2;
      orthographicCamera.updateProjectionMatrix();

      renderer.setSize(window.innerWidth, window.innerHeight);

      controls.handleResize();
    },
    tooltip(value) {
      return new Date(this.trajectories['times'][value]).toUTCString()
    },
    setAnimateIndex(value) {
      this.animateIndex = value;
    }
  }
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.body {
  overflow: hidden;
}

#visualisation {
  width: 100%;
  height: 100%;
}

.animation-controls-section {
  position: fixed;
  bottom: 0;
  width: 100%;
  margin-right: 20%;
  align-content: center;
  text-align: center;
  height: 100px;
}

.animation-controls {
  white-space: nowrap;
  width: 50%;
  margin: 0 auto;
  display: flex;

}

.play-pause-centre {
  cursor: pointer;
  height: 40px;
  display: inline-block;
  filter: invert(100%) !important;

}

.time-slider {
  margin: 15px 0;
  flex: 1;
  padding: 0 !important;
  background-color: rgba(1, 130, 196, 0.54);
}

h3 {
  color: #a0a9b3;
}

</style>
