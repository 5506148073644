<template>

  <div class="selection-controls">

    <h2>Select your objects</h2>

    <div class="planet-satellite">
      <div id="planets">
        <h3>Planets</h3>
        <ul id="planets-list">
          <button
              v-for="plnt in rankedPlanets"
              :key="plnt.name"
              type="button"
              :class="{ active:plnt.include }"
              @click="includeObject(plnt)"
          >
            <span>{{ nameParse(plnt.name) }}</span>
          </button>
        </ul>
      </div>
      <div id="satellites">
        <h3>Satellites</h3>
        <ul id="satellites-list">
          <button
              v-for="sat in satellites"
              :key="sat.name"
              type="button"
              :class="{ active:sat.include }"
              @click="includeObject(sat)"
          >
            <span>{{ nameParse(sat.name) }}</span>
          </button>
        </ul>
      </div>
    </div>
    <div id="forecast">
      <h3>Forecast</h3>
      <div class="time-interval-picker">
        <label>Starting Time</label>
        <div class="picker">
          <flat-pickr v-model="startTime" :config="{enableTime: true}" @change="emitTimeChange"></flat-pickr>
        </div>
      </div>
      <div class="time-interval-picker">
        <label>Interval</label>
        <div class="picker">
          <button @click="toggleDropDown">{{ interval.name }}</button>
          <!--          <i class="fa-solid fa-caret-down"></i>-->
          <ul class="dropdown-content" v-show="showIntervalDropdown">
            <li v-for="option in intervalOptions"
                :key="option"
                class="interval-option-li"
            >
              <span :class="{ active:option.name == interval.name }"
                    class="interval-option-span"
                    @click="updateInterval(option)">{{ option.name }}</span>
            </li>
          </ul>
          <!--          <dropdown :options="intervalOptions" :selected="interval" v-on:updateOption="updateInterval"></dropdown>-->
        </div>
      </div>
      <div id="projected-time">

        <label>Projection Time</label>
        <div class="time-label">
          <span>{{ forcastTime }}</span>
        </div>
        <vue-slider
            v-model="forecastTimeMins"
            :min="minForecastTime"
            :max="maxForecastTime"
            :min-range="30"
            :height="10"
            :process-dragable="true"
            :tooltip="'none'"
            @change="emitTimeChange"

            :process-style='{"backgroundColor": "rgb(1,130,196)"}'
            class="time-slider"
        ></vue-slider>
      </div>
    </div>
    <h3 id="webgl_errors"></h3>

  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import VueSlider from 'vue-slider-component'

import _ from "lodash"

export default {
  name: "Controls",
  components: {flatPickr, VueSlider},
  props: {
    planets: {type: Array, default: () => ([])},
    satellites: {type: Array, default: () => ([])}
  },
  data() {
    return {
      startTime: new Date(),
      interval: {},
      intervalOptionsMins: [[1, 60], [10, 360], [60, 1440], [360, 10080], [720, 20160], [1440, 525600], [10080, 1576800]],
      intervalOptions: [],
      showIntervalDropdown: false,
      minForecastTime: 0,
      maxForcastTime: 0,
      forecastTimeMins: 266400,
    }
  },
  watch: {
    interval: function (currentInterval) {
      this.minForecastTime = currentInterval.min;
      this.maxForecastTime = currentInterval.max;
    }
  },
  methods: {
    toggleDropDown: function () {
      this.showIntervalDropdown = !this.showIntervalDropdown
    },
    nameParse: function (value) {
      return value.trim().replace('_', ' ')
    },
    includeObject: function (item) {
      item.include = !item.include
    },
    emitTimeChange: function () {
      console.log("time-updated component")
      this.$emit('timeupdate', {
        "startTime": this.startTime,
        "forecastTimeMins": this.forecastTimeMins,
        "intervalMins": this.interval.value
      })
    },
    updateInterval: function (value) {
      this.interval = value
      this.forecastTimeMins = value.min + Math.floor((value.max - value.min) / 2)
      this.showIntervalDropdown = false
      this.emitTimeChange()
    },
    parseDate: function (dateTime) {
      let m = dateTime % 60
      let h = Math.floor(dateTime / 60)
      let d = Math.floor(h / 24)
      let y = Math.floor(d / 365)
      d = d - (y * 365)
      h = h - (y * 365 * 24) - (d * 24)
      let res = ""
      switch (y) {
        case 0:
          break
        case 1:
          res += y + "year ";
          break;
        default:
          res += y + "years ";
      }
      switch (d) {
        case 0:
          break
        case 1:
          res += d + "day ";
          break;
        default:
          res += d + "days ";
      }
      if (y === 0 && d <= 30) {
        switch (h) {
          case 0:
            break
          case 1:
            res += h + "hr ";
            break;
          default:
            res += h + "hrs ";
        }
      }
      if (y === 0 && d === 0 && h <= 72) {
        switch (m) {
          case 0:
            break
          case 1:
            res += m + "min ";
            break;
          default:
            res += m + "mins ";
        }
      }
      return res
    }
  },
  computed: {
    rankedPlanets() {
      return _.orderBy(this.planets, 'rank');
    },
    forcastTime() {
      return this.parseDate(this.forecastTimeMins)
    }
  },
  created: function () {
    this.intervalOptions = this.intervalOptionsMins.map((item) => {
      return {name: this.parseDate(item[0]), value: item[0], min: item[0] * 5, max: item[1]}
    })
    this.interval = this.intervalOptions[5]
  },
  mounted: function () {
    this.emitTimeChange()
  }
}
</script>

<style lang="scss" scoped>

label {
  font-weight: bold;
}


.time-interval-picker {
  display: flex;
  flex-flow: row wrap;

  label {
    display: grid;
    width: 100%;
    align-items: center;
  }

  .picker {
    display: inline-block;
    width: 100%;
    margin: 5px 0;

    button {
      background-color: #FFFFFF;
    }
  }

  input {
    margin: 10px;
    padding: 3px;
    width: 115px;
    height: 20px;
    position: relative;
    text-align: center;
  }

  .dropdown-content {
    display: block;
    margin-top: 2px;
    max-width: 400px;
    list-style-type: none;
    background-color: #FFFFFF;
    align-items: center;
    text-align: center;
    padding: 10px;
    position: absolute;
    z-index: 2;
  }

  .active{
    font-weight: bold;
  }
  .interval-option-li {
    display: inline-block;
    padding: 8px 5px 3px 5px;
    width: 115px;
    cursor: pointer;
  }
  .interval-option-span {
    color: #2c3e50;
    margin: 0 auto;
  }

  justify-content: flex-start !important;
}

#projected-time {
  margin: 15px;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start !important;
}

.time-slider {
  margin: 15px 0;
  padding: 0 !important;
  background-color: rgba(1, 130, 196, 0.54);
}

.time-label {
  margin-top: 5px
}

ul {
  padding: 0;
}


.selection-controls {
  overflow-y: scroll;
  color: #FFFFFF;
  position: fixed;
  height: available;
  max-height: 85%;
  right: 0;
  top: 0;
  width: 25%;
  min-width: 140px;
}

.selection-controls::-webkit-scrollbar {
  display: none;
}

.selection-controls {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.scrollable {
  width: 100%;
  height: 99%;
  overflow: hidden;
}

.planet-satellite {
  display: flex;
  flex-flow: row wrap;
}

#satellites {
  width: 90%;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start !important;

}

#planets {
  width: 90%;
  display: flex;
  flex-flow: column wrap;
}

#forecast {
  display: flex;
  flex-flow: row wrap;
  width: 90%;
  flex-flow: column wrap;
}

button {
  margin: 3px;
  text-transform: capitalize;

  background-color: #D5D5D5;

  border: none;
  padding: 10px 22px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  //font-size: 16px;
  font-weight: bold;
  cursor: pointer;

  &.active {
    background-color: #0182C4;
    color: #FFFFFF;
  }

}

</style>